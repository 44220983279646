import React from 'react'
// import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import favicon from './../../assets/images/foodtoura/favicon.png'

// Components
import Header from '../header'
import Footer from '../footer'

// Styles
import './default.scss'

// Fonts
require('typeface-amatic-sc')
require('typeface-shadows-into-light')

export default ({children}) => (
  <StaticQuery
    query={graphql`
      {
        site {
          siteMetadata {
            title
            description
            keywords
            navbarItems{
              label
              target
            }
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={
            data.site.siteMetadata.title +
            ' | ' +
            data.site.siteMetadata.description
          }
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}
          link={[{ rel: 'icon', type: 'image/png', href: `${favicon}` }]}
        />
        <Header siteTitle={data.site.siteMetadata.title} navbarItems={data.site.siteMetadata.navbarItems} />
        <main id="site-content">{children}</main>
        <Footer />
      </>
    )}
  />
)