import React, {Component} from 'react'
import { Link } from 'gatsby'
import Logo from './../assets/images/foodtoura/foodtoura-logo.svg'
import './header.scss'

import NavBar from 'react-scrolling-nav';

class Header extends Component {
  render() {
    return (
      <header className="Header">
        <div className="container">
          <Link to="/" className="site-logo">
            <h1 className="site-name">{this.props.siteTitle}</h1>
            <Logo className="logo" />
          </Link>
          <div id="NavBar">
            <NavBar  items={this.props.navbarItems} offset={-80} duration={500} delay={0} backgroundColor={'transparent'} style={{justifyContent: "flex-end"}} />
          </div>
        </div>
      </header>
    );
  }
}

export default Header
