import React from 'react'
import { Link } from 'gatsby'
import './footer.scss'

const Footer = () => (
  <footer className="Footer">
    <div className="container">
      <Link to="/datenschutz/">Datenschutz</Link>
      <Link to="/impressum/">Impressum</Link>
    </div>
  </footer>
)

export default Footer
